import axios from 'axios';
import { getToken, logOut, authContext } from '../Auth';
import { ConfigService } from '../configService';

axios.interceptors.request.use(config => {
    let _auth = authContext();

    let useSPAAndApiSeparately = ConfigService.get().UseSPAAndApiSeparately;
    if (useSPAAndApiSeparately === true) {
        config.baseURL = 'https://localhost:5001';
    }

    return new Promise((resolve, reject) => {
        //Check is token expired, otherwise renew a new one
        _auth.acquireToken(_auth.config.clientId, (message, _token, msg) => {
            if (_token) {
                config.headers.Authorization = `Bearer ${_token}`
                resolve(config)
            } else {
                // Do something with error of acquiring the token
                reject(config)
            }
        })
    })
}, function (error) {
    // Do something with error of the request
    return Promise.reject(error)
});

//En cas de session expirée, l'utilisateur est redirigé vers la page d'authentification
axios.interceptors.response.use(response => response,
    err => {
        if (err?.response?.status === 401) {
            alert("Votre session est expirée!");
            logOut();
            return;
        }

        return Promise.reject(err);
});