import i18n from "i18next";
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";


i18n
    .use(initReactI18next)
    .use(languageDetector)
    .init({
        supportedLngs: ["en", "fr"],
        detection: { order: ["path", "navigator"] },
        nonExplicitSupportedLngs: true,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false 
        }
    });

export default i18n;

