import './config/axios-conf';
import React, {lazy, Suspense, useEffect, useMemo, useState} from 'react';
import {UserPreferencesContextProvider} from "./shared/UserPreferencesHook";
import {UserAuthorizedLightModel} from "./model/UserAuthorizedLightModel";
import {AppService} from "./AppService";
import {LinearProgress} from "@material-ui/core";
import {ConfigService} from "./configService";
import {createInstance, MatomoProvider} from "@datapunt/matomo-tracker-react";
import {Loader} from "./shared/Loader";

const App = lazy(() => import('./App'));

const AppContainer = () => {
    const [user, setUser] = useState<UserAuthorizedLightModel>();
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        AppService.loadUser()
            .then(setUser)
            .finally(() => setLoading(false));
    }, []);

    const matomoInstance = useMemo(() => {
        const setting = ConfigService.get().matomo;
        if (!user || !user.userName || !user.userHash
            || !setting || !setting.urlBase || setting.siteId == 0) {
            console.warn("cannot set matomo", user, setting)
            return null;
        }

        return createInstance({
            urlBase: setting.urlBase,
            siteId: setting.siteId,
            userId: user.userHash
        });

    }, [user]);


    return (<>{loading ? <LinearProgress/>
        : user ? <Suspense fallback={<Loader loading={true}/>}>
                <UserPreferencesContextProvider user={user}>
                    <MatomoProvider value={matomoInstance}>
                        <App/>
                    </MatomoProvider>
                </UserPreferencesContextProvider>
            </Suspense>
            : <div>Cannot get user</div>
    }</>);
}

export default AppContainer;