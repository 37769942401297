import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import { runWithAdal } from 'react-adal';
import { authContext } from './Auth';
import { ConfigService } from './configService';
import buildinfo from './config/buildinfo.json';
import './i18n';
import { setRequestLogger } from "./RequestLogger";
import AppContainer from "./AppContainer";

window.document.title += ` v3.${buildinfo.versionMajorMinor}.${buildinfo.build.buildNumber}`;

function renderApp() {
    const baseUrl = "/";
    const rootElement = document.getElementById('root');

    runWithAdal(authContext(), () => {
        ReactDOM.render(
            <BrowserRouter basename={baseUrl}>
                    <AppContainer />
            </BrowserRouter>,
            rootElement);
        registerServiceWorker();
    }, false);
}

const settingsPromise = new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", 'config/appsettings.json');
    xhr.onload = () => resolve(xhr.responseText);
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
});
settingsPromise.then((res) => {
    ConfigService.setup(res);
    setRequestLogger();
    renderApp();
});