import React from 'react';

interface IProps {
    loading: boolean
    canClickThrough?: boolean
    fontSize?: string
}

export const Loader = (props: IProps): JSX.Element => {
    const {
        loading,
        canClickThrough,
        fontSize  
    } = props;
    
    return (loading ? (
        <div 
            className="k-loading-mask" 
            style={{
                pointerEvents: `${canClickThrough ? 'none' : 'auto'}` as React.CSSProperties["pointerEvents"],
                ...(fontSize ? { fontSize } : {})
            }}
        >
            <span className="k-loading-text">Loading...</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
        ) : null);
}