import { strings } from "src/strings";

export enum FiltersId {
    OBJECT_TYPE = "object_type",
    UE_TITLE = "ue_title",
    LEVEL2_ACTIVITIES = "level2_activities",
    DATE_YEAR = "date_year",
    DOCUMENT_DATA_CATEGORY = "document_data_category",
    DOCUMENT_EXTENSION = "document_extension",
    EQUIPMENT_TYPE = "equipment_type",
    TERRITORY = "ert_title",
    CE_LABEL = "circular_economy_groupings_labels",
    SUPPLIER_COMPANY = "supplier_company",
    SUPPLIER_SOLVENCY_SCORE = "supplier_solvency_score",
    CLIENT = "client",
    JOB_STATUS = "jobsite_status",
    PRODUCT_ID = "product_id",
    PRODUCT_LABEL = "product_label",
    PRODUCT_SPECIAL_CODE = "product_special_code",
    BUSINESS_STATUS = "econtract_status",
    PHYSICAL_SITE_TYPE = "physical_site_type",
}

export enum ObjectTypesFilterId {
    ArctiqueData = "Fournisseur (ARCTIQUE)",
    BrgmBoreholes = "Sondages BRGM",
    LegalCompany = "Société juridique",
    ExploitationUnit = "Unité d'exploitation",
    Job = "Chantier",
    MaterialEquipment = "Matériel/Equipement",
    Model3d = "Maquette 3D",
    PhysicalSite = "Site physique",
    ProductionUnit = "Unité de production",
    Project = "Affaire",
    Refinery = "Raffinerie",
    RoadStatement = "Relevé de route",
    Supplier = "Fournisseur (HUBBLE)"
}

const objectTypeFilterIcon = new Map<ObjectTypesFilterId, string>([
    [ObjectTypesFilterId.ArctiqueData, 'images/vectuel/poi/icons/Fournisseur_icon_v3.svg'],
    [ObjectTypesFilterId.BrgmBoreholes, 'images/vectuel/poi/icons/Sondage_icon_v4.svg'],
    [ObjectTypesFilterId.LegalCompany, 'images/vectuel/poi/icons/SJ_icon_v3.svg'],
    [ObjectTypesFilterId.ExploitationUnit, 'images/vectuel/poi/icons/Agence_icon_v3.svg'],
    [ObjectTypesFilterId.Job, 'images/vectuel/poi/icons/Chantier_icon_v3.svg'],
    [ObjectTypesFilterId.MaterialEquipment, 'images/vectuel/poi/icons/Materiel_icon_v3.svg'],
    [ObjectTypesFilterId.Model3d, 'images/vectuel/poi/icons/Maquette_icon_v3.svg'],
    [ObjectTypesFilterId.ProductionUnit, 'images/vectuel/poi/icons/UP Centrale_icon_v3.svg'],
    [ObjectTypesFilterId.Project, 'images/vectuel/poi/icons/Affaire_icon_v3.svg'],
    [ObjectTypesFilterId.Refinery, 'images/vectuel/poi/icons/Raffinerie_icon_v4.svg'],
    [ObjectTypesFilterId.RoadStatement, 'images/vectuel/poi/icons/Infracare_icon_v3.svg'],
    [ObjectTypesFilterId.Supplier, 'images/vectuel/poi/icons/Fournisseur_icon_v3.svg'],
    [ObjectTypesFilterId.PhysicalSite, 'images/vectuel/poi/icons//PhysicalSite_icon_v5.svg'],
])

export const sortedDefaultFilters = [
    FiltersId.TERRITORY,
    FiltersId.UE_TITLE,
    FiltersId.DOCUMENT_DATA_CATEGORY,
    FiltersId.DATE_YEAR,
];

export const getObjectTypeFilterTitle = (objectTypeFilterId: ObjectTypesFilterId | string) => {
    const objectTypeFilterLabel = new Map<ObjectTypesFilterId, string>([
        [ObjectTypesFilterId.ArctiqueData, strings.vectuelMapObjectTypeFilterPluralArctiqueData],
        [ObjectTypesFilterId.BrgmBoreholes, strings.vectuelMapObjectTypeFilterPluralBrgmBoreholes],
        [ObjectTypesFilterId.LegalCompany, strings.vectuelMapObjectTypeFilterPluralLegalCompany],
        [ObjectTypesFilterId.ExploitationUnit, strings.vectuelMapObjectTypeFilterPluralExploitationUnit],
        [ObjectTypesFilterId.Job, strings.vectuelMapObjectTypeFilterPluralJob],
        [ObjectTypesFilterId.MaterialEquipment, strings.vectuelMapObjectTypeFilterPluralMaterialEquipment],
        [ObjectTypesFilterId.Model3d, strings.vectuelMapObjectTypeFilterPluralModel3d],
        [ObjectTypesFilterId.ProductionUnit, strings.vectuelMapObjectTypeFilterPluralProductionUnit],
        [ObjectTypesFilterId.Project, strings.vectuelMapObjectTypeFilterPluralProject],
        [ObjectTypesFilterId.Refinery, strings.vectuelMapObjectTypeFilterPluralRefinery],
        [ObjectTypesFilterId.RoadStatement, strings.vectuelMapObjectTypeFilterPluralRoadStatement],
        [ObjectTypesFilterId.Supplier, strings.vectuelMapObjectTypeFilterPluralSupplier],
        [ObjectTypesFilterId.PhysicalSite, strings.vectuelMapObjectTypeFilterPluralPhysicalSite],
    ]);
    
    return objectTypeFilterId
    ? objectTypeFilterLabel.get(objectTypeFilterId as ObjectTypesFilterId) || `? ${objectTypeFilterId} ?`
    : '?'
}


export const getObjectTypeFilterTooltip = (objectTypeFilterId: ObjectTypesFilterId | string) => {
    const objectTypeFilterTooltips = new Map<ObjectTypesFilterId, string>([
        [ObjectTypesFilterId.ArctiqueData, strings.vectuelMapObjectTypeFilterPluralArctiqueDataTooltip],
        [ObjectTypesFilterId.BrgmBoreholes, strings.vectuelMapObjectTypeFilterPluralBrgmBoreholesTooltip],
        [ObjectTypesFilterId.LegalCompany, strings.vectuelMapObjectTypeFilterPluralLegalCompanyTooltip],
        [ObjectTypesFilterId.ExploitationUnit, strings.vectuelMapObjectTypeFilterPluralExploitationUnitTooltip],
        [ObjectTypesFilterId.Job, strings.vectuelMapObjectTypeFilterPluralJobTooltip],
        [ObjectTypesFilterId.MaterialEquipment, strings.vectuelMapObjectTypeFilterPluralMaterialEquipmentTooltip],
        [ObjectTypesFilterId.Model3d, strings.vectuelMapObjectTypeFilterPluralModel3dTooltip],
        [ObjectTypesFilterId.ProductionUnit, strings.vectuelMapObjectTypeFilterPluralProductionUnitTooltip],
        [ObjectTypesFilterId.Project, strings.vectuelMapObjectTypeFilterPluralProjectTooltip],
        [ObjectTypesFilterId.Refinery, strings.vectuelMapObjectTypeFilterPluralRefineryTooltip],
        [ObjectTypesFilterId.RoadStatement, strings.vectuelMapObjectTypeFilterPluralRoadStatementTooltip],
        [ObjectTypesFilterId.Supplier, strings.vectuelMapObjectTypeFilterPluralSupplierTooltip],
        [ObjectTypesFilterId.PhysicalSite, strings.vectuelMapObjectTypeFilterPluralPhysicalSiteTooltip],
    ]);

    return objectTypeFilterId
    ? objectTypeFilterTooltips.get(objectTypeFilterId as ObjectTypesFilterId) || getObjectTypeFilterTitle(objectTypeFilterId)
    : '?'
};
export const getObjectTypeFilterIcon = (filterId: ObjectTypesFilterId) => objectTypeFilterIcon.get(filterId);

export const getFilterTitle = (filterId: FiltersId | string): string => {
    const filterLabel = new Map<FiltersId, string>([
        [FiltersId.OBJECT_TYPE, strings.vectuelMapObjectType],
        [FiltersId.UE_TITLE, strings.vectuelMapUeTitle],
        [FiltersId.LEVEL2_ACTIVITIES, strings.vectuelMapLevel2Activities],
        [FiltersId.DATE_YEAR, strings.vectuelMapDateYear],
        [FiltersId.DOCUMENT_DATA_CATEGORY, strings.vectuelMapDocumentDataCategory],
        [FiltersId.DOCUMENT_EXTENSION, strings.vectuelMapDocumentExtension],
        [FiltersId.EQUIPMENT_TYPE, strings.vectuelMapEquipmentType],
        [FiltersId.TERRITORY, strings.vectuelMapErtTitle],
        [FiltersId.CE_LABEL, strings.vectuelMapCircularEconomyGrouping],
        [FiltersId.SUPPLIER_COMPANY, strings.vectuelMapSupplierCompany],
        [FiltersId.SUPPLIER_SOLVENCY_SCORE, strings.vectuelMapSupplierSolvencyScore],
        [FiltersId.CLIENT, strings.vectuelMapClient],
        [FiltersId.JOB_STATUS, strings.vectuelMapJobsiteStatus],
        [FiltersId.PRODUCT_ID, strings.vectuelMapProductId],
        [FiltersId.PRODUCT_LABEL, strings.vectuelMapProductLabel],
        [FiltersId.PRODUCT_SPECIAL_CODE, strings.vectuelMapProductSpecialCode],
        [FiltersId.BUSINESS_STATUS, strings.vectuelMapEcontractStatus],
        [FiltersId.PHYSICAL_SITE_TYPE, strings.vectuelMapPhysicalSiteType],
    ]);

    return  filterLabel.get(filterId as FiltersId) ?? filterId
};
export const getFilterTooltip = (filterId: FiltersId | string): string => {
    const filterTooltip = new Map<FiltersId, string>([
        [FiltersId.UE_TITLE, strings.vectuelMapFilterTooltipUeTitle],
        [FiltersId.LEVEL2_ACTIVITIES, strings.vectuelMapFilterTooltipLevel2Activities],
        [FiltersId.DATE_YEAR, strings.vectuelMapFilterTooltipDateYear],
        [FiltersId.DOCUMENT_DATA_CATEGORY, strings.vectuelMapFilterTooltipDocumentDataCategory],
        [FiltersId.DOCUMENT_EXTENSION, strings.vectuelMapFilterTooltipDocumentExtension],
        [FiltersId.EQUIPMENT_TYPE, strings.vectuelMapFilterTooltipEquipmentType],
        [FiltersId.TERRITORY, strings.vectuelMapFilterTooltipErtTitle],
        [FiltersId.CE_LABEL, strings.vectuelMapFilterTooltipCircularEconomyGrouping],
        [FiltersId.SUPPLIER_COMPANY, strings.vectuelMapFilterTooltipSupplierCompany],
        [FiltersId.SUPPLIER_SOLVENCY_SCORE, strings.vectuelMapFilterTooltipSupplierSolvencyScore],
        [FiltersId.CLIENT, strings.vectuelMapFilterTooltipClient],
        [FiltersId.JOB_STATUS, strings.vectuelMapFilterTooltipJobsiteStatus],
        [FiltersId.PRODUCT_ID, strings.vectuelMapFilterTooltipProductId],
        [FiltersId.PRODUCT_LABEL, strings.vectuelMapFilterTooltipProductLabel],
        [FiltersId.PRODUCT_SPECIAL_CODE, strings.vectuelMapFilterTooltipProductSpecialCode],
        [FiltersId.BUSINESS_STATUS, strings.vectuelMapFilterTooltipEcontractStatus],
        [FiltersId.PHYSICAL_SITE_TYPE, strings.vectuelMapFilterTooltipPhysicalSiteType],
    ]);

    return filterTooltip.get(filterId as FiltersId) ?? getFilterTitle(filterId)
};

export const getSortedFilters = (filtersToSort: any[]) => {
    const defaultFilters = filtersToSort
        .filter(filter => sortedDefaultFilters.includes(filter.name))
        .sort((a, b) => sortedDefaultFilters.indexOf(a.name) - sortedDefaultFilters.indexOf(b.name));

    const alphabeticalFilters = filtersToSort
        .filter(filter => !sortedDefaultFilters.includes(filter.name))
        .sort((a, b) => getFilterTitle(a.name).localeCompare(getFilterTitle(b.name)));

    return [...defaultFilters, ...alphabeticalFilters];
}