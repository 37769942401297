import { listMergeDebounce } from 'src/shared/utils';
import axios, {AxiosResponse} from 'axios';

type fetchType = (input: RequestInfo, init?: RequestInit) => Promise<Response>;
type fetchEvent = {
    startTime: Date,
    durationMillis: number,
    url: string,
    method: string,
    body: string,

    ok: boolean,
    status: number,
    responseType: string
}

export function setRequestLogger(){
    const {fetch: originalFetch} = window;
    const loggerDecorator = fetchLoggerDecorator(originalFetch);
    window.fetch = loggerDecorator;
}

function fetchLoggerDecorator(fetch:fetchType) : fetchType  {
    const logEvent = listMergeDebounce((data:string[]) => {
        sendData(data).catch(console.error);
    }, 2000);
    
    return async (input: RequestInfo, init?: RequestInit) => {
        const startTime = new Date();
        const startMillis = performance.now();

        const response = await fetch(input, init);

        try{
            const durationMillis = performance.now() - startMillis;
            const requestInfos = getRequestInfos(input, init);
            const responseInfos = getResponseInfos(response);
            const ev:fetchEvent = {
                startTime, durationMillis,
                ...requestInfos,
                ...responseInfos,
                url : requestInfos.url || responseInfos.url
            };
            
            logEvent(JSON.stringify(ev));
        }catch(e){
            console.error(e);
        }

        return response;
    };
}

async function sendData(data : string[]){
    if (!data || data.length == 0) {
        return;
    }
    
    await axios.post<string[], AxiosResponse<void>>('api/monitoring/log-data', data);
}

function getRequestInfos(input: RequestInfo, init?: RequestInit) : {url: string, method: string, body: string}{
    let url: string = null, method: string = null, body: string = null;

    if(init){
        method = init.method;
        if(typeof init.body == 'string'){
            body = init.body;
        }
    }

    if(input){
        if(typeof input == 'string'){
            url = input;
            return {url, method, body};
        }else{
            url = input.url;
            method = input.method;
        }
    }

    return {url, method, body};
}

function getResponseInfos(response: Response) : {ok: boolean, status: number, responseType: string, url: string}{
    return {ok: response.ok,
        status: response.status,
        responseType: response.type,
        url: response.url};
}
